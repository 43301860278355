<template>
  <div>
    <v-data-table :headers="headers" :items="items" dense @click:row="onClickRow" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="12" sm="12" md="9" lg="9">
              <v-text-field
                flat
                dense
                outlined
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="success"
                    v-on="on"
                    dark
                    rounded
                    :to="{ name: 'Create Income Statement' }"
                    class="mr-2"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Create</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" dark rounded @click="openDialog">
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Create Bulk</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.no="{ item }">
        {{ items.findIndex(x => x === item) + 1 }}
      </template>
      <template v-slot:item.month="{ item }">
        {{ listMonth.find(x => x.value === item.month).text }}
      </template>
      <template v-slot:item.type="{ item }">
        {{ listType.find(x => x.value === item.type).text }}
      </template>
      <template v-slot:item.amount="{ item }">
        {{ formatPrice(item.amount) }}
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getInitialData">
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <dialog-preview
      :dialog="dialog"
      :listType="listType"
      @close="close"
      @save="saveBulk"
    ></dialog-preview>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DialogPreview from "./DialogPreview.vue";

export default {
  name: "income-statement",
  components: {
    DialogPreview,
  },
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Type",
        value: "type",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Month",
        value: "month",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Year",
        value: "year",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Amount",
        value: "amount",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    search: "",
    items: [],
    dialog: false,
    listMonth: [
      {
        text: "Januari",
        value: 1,
      },
      {
        text: "Februari",
        value: 2,
      },
      {
        text: "Maret",
        value: 3,
      },
      {
        text: "April",
        value: 4,
      },
      {
        text: "Mei",
        value: 5,
      },
      {
        text: "Juni",
        value: 6,
      },
      {
        text: "Juli",
        value: 7,
      },
      {
        text: "Agustus",
        value: 8,
      },
      {
        text: "September",
        value: 9,
      },
      {
        text: "Oktober",
        value: 10,
      },
      {
        text: "November",
        value: 11,
      },
      {
        text: "Desember",
        value: 12,
      },
    ],
  }),
  computed: {
    ...mapState("incomeStatement", ["listType"]),
  },
  created() {
    this.getInitialData();
  },

  methods: {
    async getAll() {
      await this.$store.dispatch("incomeStatement/getAll").then(response => {
        this.items = response.data;
      });
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Income Statement", params: { id: item.id } });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    openDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    saveBulk(items) {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("incomeStatement/saveBulk", items)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getAll(), this.$store.dispatch("incomeStatement/getType")])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
