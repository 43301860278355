<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Income Statement</span>
      </v-card-title>
      <v-card-text>
        <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        <v-data-table :headers="headers" :items="items" dense v-else>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="3">
                  <v-select
                    outlined
                    flat
                    dense
                    background-color="white"
                    v-model.number="params.month"
                    :items="listMonth"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-checkbox
                    background-color="white"
                    label="Working Capital"
                    v-model="wc"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="2">
                  <v-btn color="primary" @click="search"><v-icon>mdi-magnify</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.no="{ item }">
            {{ items.findIndex(x => x === item) + 1 }}
          </template>
          <template v-slot:item.month="{ item }">
            {{ listMonth.find(x => x.value === item.month).text }}
          </template>
          <template v-slot:item.type="{ item }">
            {{ listType.find(x => x.value === item.type).text }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ formatPrice(item.amount) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" class="mr-2">
          Close
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-preview",
  props: {
    dialog: Boolean,
    listType: Array,
  },
  data() {
    return {
      params: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
      },
      wc: false,
      items: [],
      loading: false,
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Type",
          value: "type",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Month",
          value: "month",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 4,
          text: "Year",
          value: "year",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 5,
          text: "Amount",
          value: "amount",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
      listMonth: [
        {
          text: "Januari",
          value: 1,
        },
        {
          text: "Februari",
          value: 2,
        },
        {
          text: "Maret",
          value: 3,
        },
        {
          text: "April",
          value: 4,
        },
        {
          text: "Mei",
          value: 5,
        },
        {
          text: "Juni",
          value: 6,
        },
        {
          text: "Juli",
          value: 7,
        },
        {
          text: "Agustus",
          value: 8,
        },
        {
          text: "September",
          value: 9,
        },
        {
          text: "Oktober",
          value: 10,
        },
        {
          text: "November",
          value: 11,
        },
        {
          text: "Desember",
          value: 12,
        },
      ],
    };
  },
  methods: {
    save() {
      this.$emit("save", this.items);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    search() {
      this.loading = true;
      if (this.wc) {
        this.$store.dispatch("incomeStatement/getWcPreview", this.params).then(response => {
          this.items = response.data;
          this.loading = false;
        });
      } else {
        this.$store.dispatch("incomeStatement/getPreview", this.params).then(response => {
          this.items = response.data;
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style></style>
